@font-face {
    font-family: "Montserrat Thin";
    src: local("Montserrat Thin"),
      url("../../public/fonts/Montserrat-Thin.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat Thin Italic";
    src: local("Montserrat Thin Italic"),
      url("../../public/fonts/Montserrat-ThinItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat Light";
    src: local("Montserrat Light"),
      url("../../public/fonts/Montserrat-Light.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat Light Italic";
    src: local("Montserrat Light Italic"),
      url("../../public/fonts/Montserrat-LightItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat Extra Light";
    src: local("Montserrat Light"),
      url("../../public/fonts/Montserrat-ExtraLight.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat Extra Light Italic";
    src: local("Montserrat Extra Light Italic"),
      url("../../public/fonts/Montserrat-ExtraLightItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat Medium";
    src: local("Montserrat Medium"),
      url("../../public/fonts/Montserrat-Medium.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat Medium Italic";
    src: local("Montserrat Medium Italic"),
      url("../../public/fonts/Montserrat-MediumItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat Regular";
    src: local("Montserrat Regular"),
      url("../../public/fonts/Montserrat-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat Semi Bold";
    src: local("Montserrat Semi Bold"),
      url("../../public/fonts/Montserrat-SemiBold.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat Semi Bold Italic";
    src: local("Montserrat Semi Bold Italic"),
      url("../../public/fonts/Montserrat-SemiBoldItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat Bold";
    src: local("Montserrat Bold"),
      url("../../public/fonts/Montserrat-Bold.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat Bold Italic";
    src: local("Montserrat Bold Italic"),
      url("../../public/fonts/Montserrat-BoldItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat Extra Bold";
    src: local("Montserrat Extra Bold"),
      url("../../public/fonts/Montserrat-ExtraBold.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat Extra Bold Italic";
    src: local("Montserrat Extra Bold Italic"),
      url("../../public/fonts/Montserrat-ExtraBoldItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat Black";
    src: local("Montserrat Black"),
      url("../../public/fonts/Montserrat-Black.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat Black Italic";
    src: local("Montserrat Black Italic"),
      url("../../public/fonts/Montserrat-BlackItalic.ttf") format("truetype");
  }