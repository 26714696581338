/* The container */
.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 0.9em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $blue-primary;
  text-transform: capitalize;
  line-height: 1.8em;
  font-family: map-get($font, "semibold");
  margin-right: 20px;
  float: left;
}
.checkbox:last-child{
  margin: 0px;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: $blue-primary;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
  background-color: $blue-dark;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
  background-color: $red-primary;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid $white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.contactUsDetailsFormStepInputButton{
    width: $full-width;
    float: left;
    background: $red-primary;
    border-radius: 50px;
    padding: 15px 0px;
    cursor: pointer;
    font-size: 1em;
    text-transform: capitalize;
}

.contactUsDetailsFormStepInputButton:hover{
    background: $white;
    color: $blue-primary;
}


.checkbox input:disabled ~ .checkmark {
  background-color: $dark-grey;
}