@import "./fonts";
@import "./variables";
@import "./resets";
@import "./checkbox";
@import "./radiobutton";
@import "./scrollbar";

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0c2447;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #295aa4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #154387;
}

.disableClick{pointer-events: none;}

// pagination starts
.dashboardContentTablePagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
.dashboardContentTablePaginationInner {
  float: right;
}
.dashboardContentTablePaginationAction {
  float: left;
  margin-right: 15px;
}
.dashboardContentTablePaginationActionTxt:hover,
.active > .dashboardContentTablePaginationActionTxt {
  background: #000;
}
.dashboardContentTablePaginationAction:last-child {
  margin: 0px;
}
.dashboardContentTablePaginationActionTxt {
  width: 100%;
  padding: 0px 30px;
  height: 30px;
  float: left;
  font-family: "Montserrat Semi Bold";
  font-size: 0.85em;
  text-align: center;
  background: #0a1f3e;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #fff;
}
.dashboardContentTablePaginationPages {
  float: left;
  margin: 0px 15px 0px 0px;
}
.dashboardContentTablePaginationPageSingle {
  float: left;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #0a1f3e;
  margin-right: 15px;
}
.dashboardContentTablePaginationPageSingle:last-child {
  margin: 0px;
}
.dashboardContentTablePaginationPageSingle:hover {
  background: #0a1f3e;
  cursor: pointer;
}

.dashboardContentTablePaginationPageSingle.active {
  background: #0a1f3e;
  cursor: pointer;
}

.dashboardContentTablePaginationPageSingle:hover
  .dashboardContentTablePaginationPageSingleTxt {
  color: #fff;
}

.dashboardContentTablePaginationPageSingle.active
  .dashboardContentTablePaginationPageSingleTxt {
  color: #fff;
}

.dashboardContentTablePaginationPageSingleTxt {
  width: 100%;
  float: left;
  font-family: "Montserrat Semi Bold";
  font-size: 0.85em;
  text-align: center;
  text-transform: uppercase;
  color: #0a1f3e;
}
// pagination ends

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hCenter {
  display: flex;
  justify-content: center;
}
.vCenter {
  display: flex;
  align-items: center;
}
.txtCenter {
  text-align: center;
}

.capitalize {
  text-transform: capitalize;
}

.fullWidth {
  width: $full-width;
}

.textPrimary {
  color: $blue-primary;
}
.padding {
  padding: $padding;
}
.paddingTop {
  padding-top: $padding;
}
.paddingLeft {
  padding-left: $padding;
}
.paddingRight {
  padding-right: $padding;
}
.paddingBottom {
  padding-bottom: $padding;
}

.emailTxt {
  text-transform: none !important;
}

.marginTop10{margin-top: 10px !important;}

.pageHeadingTxt {
  font-size: 1.8em;
  float: left;
  font-family: map-get($font, "semibold");
  text-transform: capitalize;
}
.pageSubHeadingTxt {
  float: left;
  color: $text-dark;
  font-size: 1em;
  font-family: map-get($font, "semibold");
}

.login {
  width: $full-width;
  float: left;
  height: calc(100vh - 35px);
  // background-image: linear-gradient(
  //     180deg,
  //     rgba(255, 255, 255, 0.1),
  //     rgba(255, 255, 255, 0.1)
  //   ),
  //   url("../../public/images/login-bg.jpg");
  background-image: url("../../public/images/login-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginContent {
  width: 500px;
  float: left;
  padding: $padding;
  background: $white;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}
.loginContentInner {
  width: $full-width;
  float: left;
}
.loginContentLogo {
  width: $full-width;
  float: left;
}
.loginContentLogoImage {
  width: 130px;
  height: 130px;
  background: $blue-darker;
  border-radius: 15px;
}
.loginContentLogoImageInner {
  width: 75%;
  float: left;
}
.loginContentLogoImg {
  width: 100%;
  float: left;
}

.loginFormContent {
  width: $full-width;
  float: left;
  margin-top: $padding;
}
.loginFormContentHeading {
  width: $full-width;
  float: left;
}

.typography {
  float: left;
}

.formContainer {
  width: $full-width;
  float: left;
}
.formStep {
  width: $full-width;
  float: left;
  margin-bottom: 15px;
}
.formStep:last-child {
  margin: 0px;
}
.formStepLable {
  width: $full-width;
  float: left;
  padding-left: 15px;
}
.formStepLableTxt {
  float: left;
  font-size: 0.9em;
  font-family: map-get($font, "semibold");
  text-transform: capitalize;
  color: $blue-primary;
}
.formStepLableTxtCheck {
  color: $red-primary;
  margin-left: 5px;
}
.formStepInput {
  width: $full-width;
  float: left;
  margin-top: 5px;
  min-height: 45px;
  position: relative;
}
.formStepInputTxt {
  background: #dbdbdb;
  float: left;
  padding: 12px 15px;
  border-radius: 5px;
  font-size: 0.9em;
  font-family: map-get($font, "semibold");
  width: $full-width;
}
.formStepInputSelect {
  background: #dbdbdb;
  float: left;
  padding: 12px 15px;
  border-radius: 5px;
  font-size: 0.9em;
  font-family: map-get($font, "semibold");
  width: $full-width;
}
.formStepInputTxtarea {
  background: #dbdbdb;
  float: left;
  padding: 12px 15px;
  border-radius: 5px;
  font-size: 0.9em;
  font-family: map-get($font, "semibold");
  width: $full-width;
  resize: none;
}

.formStepFileSingle{width: 150px;float: left;overflow: hidden;margin-right: 20px;}
.formStepFileSingle:last-child {margin: 0px;}

.formStepFile {
  width: 150px;
  height: 150px;
  float: left;
  border: 2px dashed $blue-primary;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin-right: 20px;
}
.formStepFileMain {
  width: $full-width;
  float: left;
}
.formStepFileMainIcon {
  width: $full-width;
  float: left;
}
.formStepFileMainIco {
  text-align: center;
  color: $blue-primary;
  font-size: 3em;
  width: $full-width;
}
.formStepFileMainLable {
  width: $full-width;
  float: left;
  margin-top: 5px;
  padding: 0px 5px;
}
.formStepFileMainLableTxt {
  float: left;
  font-size: 0.9em;
  color: $blue-primary;
  text-transform: capitalize;
  text-align: center;
}

.formStepFileMainPreview {
  width: $full-width;
  height: $full-width;
  float: left;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}
.formStepFileMainPreviewImage {
  float: left;
  width: $full-width;
  height: $full-width;
}
.formStepFileMainPreviewImg {
  float: left;
  max-height: $full-width;
  max-width: $full-width;
}

.formStepError {
  width: $full-width;
  float: left;
  padding-left: 15px;
  margin-top: 5px;
}
.formStepErrorTxt {
  float: left;
  color: $red-primary;
  text-transform: capitalize;
  font-size: 0.8em;
  font-family: map-get($font, "semibold");
}
.formStepInputButton {
  float: left;
  padding: 0 40px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  font-family: map-get($font, "semibold");
  text-transform: capitalize;
}
.formStepInputButton.primary {
  background: $blue-primary;
  color: $white;
}
.formStepInputButton.primary:hover {
  background: $blue-darker;
}
.formStepInputButton.secondary {
  background: $red-primary;
  color: $white;
  margin-right: 15px;
}
.formStepInputButton.secondary:hover {
  background: $red-dark;
}
.formActions {
  display: flex;
  justify-content: right;
}

.footer {
  width: $full-width;
  float: left;
  background: $brand-light;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  box-shadow: inset 0 5px 5px rgb(0 0 0 / 50%);
}
.footerCopyright {
  float: left;
}
.footerPowered {
  float: left;
}
.footerCopyrightTxt {
  text-transform: capitalize;
  float: left;
  color: $white;
  font-size: 0.8em;
}
.footerPoweredTxt {
  text-transform: capitalize;
  float: left;
  color: $white;
  font-size: 0.8em;
}

.dashboardContentHeader {
  width: $full-width;
  float: left;
  height: 68px;
  background: $white;
  box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0px;
  z-index: 3;
}
.dashboardContentHeaderIcon {
  width: 68px;
  height: 68px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.dashboardContentHeaderIco {
  float: left;
  font-size: 2em;
  color: #707070;
}
.dashboardContentHeaderRight {
  float: right;
  height: 100%;
}
.dashboardContentHeaderSmall {
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.dashboardContentHeaderSmall:hover {
  background: #e2e6ef;
}

.dashboardContentHeaderLogout {
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: $blue-primary;
}
.dashboardContentHeaderLogout:hover {
  background: $blue-darker;
}

.dashboardContentHeaderLogoutInner {
  float: left;
  width: 64px;
  display: flex;
  justify-content: center;
}
.dashboardContentHeaderLogoutIcon {
  float: left;
  position: relative;
}
.dashboardContentHeaderLogoutIco {
  font-size: 1.8em;
  color: $white;
}

.dashboardContentHeaderSmallInner {
  float: left;
  width: 64px;
  display: flex;
  justify-content: center;
}
.dashboardContentHeaderSmallIcon {
  float: left;
  position: relative;
}
.dashboardContentHeaderSmallIco {
  font-size: 1.8em;
  color: #707070;
}
.dashboardContentHeaderSmallIconNew {
  position: absolute;
  right: -2px;
  top: -2px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #10c71f;
}
.notificationIoconNew {
  background: $red-primary;
}
.dashboardContentHeaderUser {
  float: left;
  height: 100%;
  padding: 0px 20px;
  background: #e2e6ef;
}
.dashboardContentHeaderUserName {
  float: left;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.dashboardContentHeaderUserNameTxt {
  float: left;
  text-transform: capitalize;
}
.dashboardContentHeaderUserPic {
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
}
.dashboardContentHeaderUserPicImage {
  width: 40px;
  height: 40px;
  float: left;
  border-radius: 40px;
  border: 2px solid #c3c3c3;
  overflow: hidden;
}
.dashboardContentHeaderUserPicImg {
  width: $full-width;
  height: $full-width;
  float: left;
}

.dashboardNav {
  width: 60px;
  height: 100%;
  background: $blue-primary;
  float: left;
  position: fixed;
  box-shadow: 4px 0px 3px rgba(0, 0, 0, 0.2);
  z-index: 9;
}
.dashboardNav.active {
  width: 250px;
}
.dashboardNavHeader {
  width: $full-width;
  float: left;
  height: 68px;
  border-bottom: 2px solid #233656;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #0a1f3e;
  z-index: 2;
}
.dashboardNavHeaderText {
  float: left;
}
.dashboardNavHeaderTxt {
  float: left;
  color: $white;
  text-transform: uppercase;
  font-size: 1em;
  font-family: map-get($font, "bold");
}
.dashboardNavMain {
  width: 100%;
  float: left;
  padding: 88px 0px 20px;
  height: 100vh;
  overflow-y: auto;
}
.dashboardNavInner {
  width: 100%;
  float: left;
}
.dashboardNavSection {
  width: 100%;
  float: left;
  margin-top: 15px;
}
.dashboardNavSection:first-child {
  margin: 0px;
}
.dashboardNavSectionHeading {
  width: 100%;
  float: left;
  padding: 0px 20px;
  margin-bottom: 5px;
}
.dashboardNavSectionHeadingTxt {
  width: 100%;
  float: left;
  font-size: 1em;
  color: $white;
  font-family: map-get($font, "bold");
  text-transform: uppercase;
}
.dashboardNavSectionSingle {
  width: 100%;
  float: left;
  height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.dashboardNavSectionSingle:hover {
  background: #081a34;
}
.dashboardNavSectionSingle:hover
  .dashboardNavSectionSingleIcon
  .dashboardNavSectionSingleIco {
  color: $white;
}
.dashboardNavSectionSingle:hover
  .dashboardNavSectionSingleContent
  .dashboardNavSectionSingleText
  .dashboardNavSectionSingleTxt {
  color: $white;
}

.dashboardNavSectionSingle.active
  .dashboardNavSectionSingleIcon
  .dashboardNavSectionSingleIco {
  color: $white;
}
.dashboardNavSectionSingle.active
  .dashboardNavSectionSingleContent
  .dashboardNavSectionSingleText
  .dashboardNavSectionSingleTxt {
  color: $white;
}

.dashboardNavSectionSingleIcon {
  width: 30px;
  height: 100%;
  left: 15px;
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboardNavSectionSingleIcon.noNav {
  left: 15px;
}
.dashboardNavSectionSingleIco {
  color: $blue-lighter;
  font-size: 1em;
}
.dashboardNavSectionSingleContent {
  width: 100%;
  float: left;
  padding: 0px 20px 0px 55px;
}
.dashboardNavSectionSingleText {
  float: left;
}
.dashboardNavSectionSingleTxt {
  color: $blue-lighter;
  font-size: 0.9em;
  text-transform: capitalize;
  font-family: map-get($font, "semibold");
}

.dashboardContainer {
  height: 100vh;
  float: left;
  background: #ecf0fb;
  position: fixed;
  right: 0px;
  overflow-y: auto;
}
.dashboardContainer.full {
  width: calc(100% - 60px);
}
.dashboardContainer.hasNav {
  width: calc(100% - 250px);
}

.dashboardContents {
  width: 100%;
  float: left;
  padding: 20px;
}
.dashboardContentHeadings {
  width: 100%;
  float: left;
  position: relative;
}
.dashboardContentHeadingLeft {
  width: 60%;
  float: left;
}
.dashboardContentHeadingText {
  width: 100%;
  float: left;
}
.dashboardContentSubHeadingText {
  width: 100%;
  float: left;
  margin-top: 7px;
}

.plainContainer {
  float: left;
  position: relative;
}

.splitContainer {
  float: left;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 15px;
}

.splitContainer .formStep{margin: 0px !important;}

.statsLableTxt {
  float: right;
  color: $white;
  font-size: 1em;
  font-family: map-get($font, "semibold");
  text-transform: capitalize;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.statsValueTxt {
  float: right;
  color: $white;
  font-size: 2.5em;
  font-family: map-get($font, "bold");
}

.sectionHeadingTxt {
  width: $full-width;
  float: left;
  color: $full-width;
  font-family: map-get($font, "semibold");
  font-size: 1.6em;
  text-transform: capitalize;
}
.sectionDescriptionTxt {
  width: $full-width;
  float: left;
  color: $text-dark;
  font-family: map-get($font, "semibold");
  font-size: 0.9em;
  text-transform: capitalize;
}

.actionButton {
  float: left;
  padding: 0 40px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  font-family: map-get($font, "semibold");
  text-transform: capitalize;
}
.actionButton.primary {
  background: $blue-primary;
  color: $white;
}
.actionButton.primary:hover {
  background: $blue-darker;
}
.actionButton.success {
  background: #278855;
  color: $white;
}
.actionButton.success:hover {
  background: #17683d;
}
.actionButton.danger {
  background: #c84114;
  color: $white;
}
.actionButton.danger:hover {
  background: #952905;
}


.iconButton {
  float: left;
  padding: 0 15px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  font-family: map-get($font, "semibold");
  text-transform: capitalize;
}
.iconButton.primary {
  background: $blue-primary;
  color: $white;
}
.iconButton.primary:hover {
  background: $blue-darker;
}
.iconButton.success {
  background: #278855;
  color: $white;
}
.iconButton.success:hover {
  background: #17683d;
}
.iconButton.danger {
  background: #c84114;
  color: $white;
}
.iconButton.danger:hover {
  background: #952905;
}

.tableButton {
  float: left;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  font-family: map-get($font, "semibold");
  text-transform: capitalize;
}
.tableButton.primary {
  background: $blue-primary;
  color: $white;
}
.tableButton.primary:hover {
  background: $blue-darker;
}
.tableButton.success {
  background: #278855;
  color: $white;
}
.tableButton.success:hover {
  background: #17683d;
}
.tableButton.danger {
  background: #c84114;
  color: $white;
}
.tableButton.danger:hover {
  background: #952905;
}

.tableHeadingItem {
  height: 45px;
  border-right: 1px solid $blue-primary;
}
.tableHeadingItem.col1 {
  min-width: 70px;
}
.tableHeadingItem.col2 {
  min-width: 150px;
}
.tableHeadingItem.col3 {
  min-width: 230px;
}
.tableHeadingItem.col4 {
  min-width: 320px;
}
.tableHeadingItem:last-child {
  border: 0px;
}

.tableHeadingTxt {
  text-transform: uppercase;
  color: $text-dark;
  font-family: map-get($font, "bold");
  font-size: 1em;
}

.statSingle {
  width: 24%;
  float: left;
  margin: 0 1.33% 0px 0;
}
.statSingle:last-child {
  margin: 0px;
}

.tableRow {
  width: $full-width;
  float: left;
  display: flex;
  flex-direction: row;
  background: #ecf0fb;
  border-bottom: 1px solid $text-dark;
}
.tableRow:nth-child(2n) {
  background: $white;
}
.tableRow:last-child {
  border-bottom: 0px;
}

.tableRowItem {
  height: 45px;
  border-right: 1px solid $blue-primary;
}
.tableRowItem.col1 {
  min-width: 70px;
}
.tableRowItem.col2 {
  min-width: 150px;
}
.tableRowItem.col3 {
  min-width: 230px;
}
.tableRowItem.col4 {
  min-width: 320px;
}
.tableRowItem:last-child {
  border: 0px;
}

.tableContentTxt {
  color: $text-dark;
  font-family: map-get($font, "semibold");
  font-size: 0.95em;
  text-transform: capitalize;
}

.popUp {
  width: $full-width;
  height: 100%;
  float: left;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;
}
.popUpBackground {
  width: $full-width;
  float: left;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0px;
  left: 0px;
}

.popUpMain {
  float: left;
  background: $white;
  border-radius: 10px;
  position: relative;
  z-index: 99;
  width: 500px;
  max-height: 600px;
  overflow-y: auto;
}
.popUpLable {
  position: sticky;
  top: 0px;
  left: 0px;
  width: $full-width;
  background-color: $white;
  float: left;
  padding: 18px 0px;
  border-bottom: 3px solid $blue-primary;
  z-index: 999;

}
.popupLableTxt {
  text-transform: capitalize;
  color: $text-dark;
  font-family: map-get($font, "semibold");
  font-size: 1.2em;
}
.popUpContent {
  width: $full-width;
  padding: 25px;
  float: left;
}

.popUpActions {
  width: $full-width;
  float: left;
  padding: 0px 0px 25px;
}
.popUpActionsInner {
  float: left;
}

.fileUploadSelection {
  width: $full-width;
  float: left;
  position: relative;
  height: 240px;
  border-radius: 10px;
  border: 3px dashed $blue-primary;
}
.fileUploadSelectionMain {
  width: $full-width;
  float: left;
  height: 100%;
  flex-direction: column;
}
.fileUploadSelectionIcon {
  float: left;
}
.fileUploadSelectionIco {
  float: left;
  font-size: 4em;
  color: $blue-primary;
}
.fileUploadSelectionLable {
  float: left;
  margin-top: 7px;
}
.fileUploadLableTxt {
  text-transform: capitalize;
  color: $text-dark;
  font-family: map-get($font, "semibold");
  font-size: 0.9em;
  color: $blue-primary;
}

.fileUploadSelectionInput {
  position: absolute;
  left: 0px;
  top: 0px;
  width: $full-width;
  height: $full-width;
  cursor: pointer;
  opacity: 0.01;
  z-index: 3;
}

.fileUploadSelectionPreview {
  position: absolute;
  left: 0px;
  top: 0px;
  height: $full-width;
  width: $full-width;
  float: left;
  z-index: 2;
}
.fileUploadSelectionPreviewImage {
  max-width: $full-width;
  height: 80%;
  float: left;
}
.fileUploadSelectionPreviewImg {
  max-width: $full-width;
  height: 100%;
  float: left;
}

.tableRowItemImage {
  width: $full-width;
  float: left;
}
.tableRowItemImageMain {
  width: 35px;
  height: 35px;
  float: left;
  border: 1px dashed $blue-primary;
  border-radius: 3px;
  overflow: hidden;
  padding: 3px;
}
.tableRowItemImg {
  max-width: $full-width;
  height: 100%;
  float: left;
}

.tableRowItemIcon {
  width: $full-width;
  float: left;
}
.tableRowItemIcon {
  font-size: 1.2em;
  color: $blue-primary;
  float: left;
}

.pageLinkSection{width: $full-width;float: left;display: flex;flex-direction: row;gap: 20px;}

.pageLinkButton{width: 25%;float: left;height: 45px;background: $blue-primary;display: flex;flex-direction: row;align-items: center;justify-content: space-between;}
.pageLinkButton:hover{background: #163f7b;}
.pageLinkButtonLable{float: left;padding-left: 20px;}
.pageLinkButtonLableTxt{float: left;color: $white;text-transform: capitalize;font-family: map-get($font, 'semibold');font-size: 0.95em;}
.pageLinkButtonIcon{background: #163f7b;width: 45px;height: 45px;}
.pageLinkButtonIco{float: left;color: $white;}

.detailSection{width: $full-width;float: left;border: 2px solid $blue-primary;}
.detailSectionHeading{width: $full-width;background: $blue-primary;float: left;height: 45px;padding: 0px 10px 2px;display: flex;justify-content: space-between;align-items: center;}
.detailSectionHeadingLable{float: left;}
.detailSectionHeadingLableTxt{float: left;text-transform: capitalize;color: $white;font-size: 1em;font-family: map-get($font, "semibold");text-indent: 5px;}

.detailSectionHeadingActions{float: left;display: flex;flex-direction: row;gap: 10px;}
.detailSectionHeadingButton{float: left;height: 30px;border-radius: 5px;cursor: pointer;font-size: 0.9em;font-family: map-get($font, "semibold");color: $blue-primary;text-transform: capitalize;padding: 0px 20px;background: $white;}
.detailSectionHeadingButton:hover{background: $grey;}

.detailSectionContent{width: $full-width;float: left;}
.detailSectionContent.hasPadding{padding: 15px 15px;}

.detailSectionRow{width: $full-width;float: left;margin-bottom: 15px;display: flex;flex-direction: row;}
.detailSectionRow:last-child{margin: 0px;}
.detailSectionRowLable{flex: 1;padding-right: 50px;float: left;}
.detailSectionRowLableTxt{float: left;font-family: map-get($font, 'semibold');color: $blue-primary;font-size: 0.9em;text-transform: capitalize;margin-top: 4px;}
.detailSectionRowSeperator{width: 10px;float: left;display: flex;justify-content: center;}
.detailSectionRowSeperatorTxt{float: left;font-family: map-get($font, 'semibold');color: $blue-primary;font-size: 0.9em;text-transform: capitalize;margin-top: 2px;}
.detailSectionRowContent{flex: 4;float: left;padding-left: 20px;}
.detailSectionRowContentTxt{float: left;font-family: map-get($font, 'semibold');color: $blue-primary;font-size: 0.9em;text-transform: capitalize;text-align: justify;line-height: 1.8em;}
.detailSectionRowContentTxt:last-child .detailSectionRowContentTxtLoopComa{display: none;}
.detailSectionRowContentImage{float: left;max-width: 100px;max-height: 100px;}

/*Form Auto Complete Dropdown*/
.dashboardContentMainFormStepInputAutoComplete {width: $full-width;float: left;position: absolute;top: 50px;background-color: #f3f3f3;border-radius: 5px;overflow: hidden;box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);z-index: 999;}
.dashboardContentMainFormStepInputAutoCompleteSingle {width: $full-width;float: left;padding: 10px 15px;cursor: pointer;text-transform: capitalize;}
.dashboardContentMainFormStepInputAutoCompleteSingle:nth-child(2n) {background-color: #e8e8e8;}
.dashboardContentMainFormStepInputAutoCompleteSingle:hover {background-color: #dadada;}
/*Form Auto Complete Dropdown*/

/*form Auto Complete Selected Items*/
.dashboardContentMainFormStepInputSelectedAutoComplete {width: $full-width;margin-top: 15px;float: left;}
.dashboardContentMainFormStepInputSelectedAutoCompleteSingle {float: left;padding: 10px 15px;float: left;border: 1px solid $blue-darker;border-radius: 5px;margin: 0px 15px 0px 0px;}
.dashboardContentMainFormStepInputSelectedAutoCompleteSingleInner {width: $full-width;float: left;display: flex;align-items: center;}
.dashboardContentMainFormStepInputSelectedAutoCompleteSingleTxt {float: left;text-transform: capitalize;font-size: 1em;}
.dashboardContentMainFormStepInputSelectedAutoCompleteSingleRemove {float: left; margin-left: 10px;cursor: pointer;}
.dashboardContentMainFormStepInputSelectedAutoCompleteSingleRemoveIco {float: left;font-size: 1.1em !important;}
/*form Auto Complete Selected Items*/

.orderDetailsProducts{width: 100%;float: left;margin-bottom: 20px;}
.orderDetailsProducts:last-child{margin: 0px;}

.noDataFound{width: $full-width;float: left;display: flex;justify-content: center;}
.noDataFoundMain{padding: 40px 80px;border: 2px dashed $blue-primary;border-radius: 30px;}
.noDataFoundInner{width: $full-width;float: left;}
.noDataFoundVisual{width: $full-width;display: flex;justify-content: center;}
.noDataFoundIcon{float: left;}
.noDataFoundIconInner{float: left;}
.noDataFoundIco{color: $blue-primary;font-size: 4em;}
.noDataFoundContent{width: $full-width;float: left;margin-top: 20px;display: flex;justify-content: center;flex-direction: column;}
.noDataFoundContentHeading{width: $full-width;display: flex;justify-content: center;}
.noDataFoundContentHeadingTxt{float: left;font-size: 1.4em;color: $blue-primary;text-transform: capitalize;font-family: map-get($font, 'semibold');}
.noDataFoundContentDescription{width: $full-width;float: left;margin-top: 5px;justify-content: center;}
.noDataFoundContentDescriptionTxt{width: $full-width;float: left;text-align: center;font-size: 0.85em;}
.noDataFoundAction{width: $full-width;float: left;margin-top: 20px;display: flex;justify-content: center;}
.noDataFoundActionInner{float: left;display: flex;flex-direction: row;}
.noDataFoundActionSingle{float: left;margin-right: 20px;}
.noDataFoundActionSingle:last-child{margin: 0px;}

.popUpHeaderClose{float: left;position: absolute;right: 15px;top: 14px;cursor: pointer;}
.popUpHeaderCloseIco{font-size: 2em;color: $blue-primary;}

.variantSingle{width: $full-width;float:left;box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);padding: 16px 20px 10px 20px;border-radius: 10px;margin-bottom: 20px;}
.variantSingle:last-child{margin: 0px;}
.variantSingleActions .splitContainer{margin-bottom: 10px;gap: 10px;}
.variantSingleHeading{width: $full-width;float: left;margin-bottom: 10px;}
.variantSingleHeadingTxt{font-family: map-get($font, 'bold');color: $blue-primary;font-size: 1.2em;text-transform: uppercase;}
.variantSinglePrice{width: $full-width;float: left;display: flex;justify-content: space-between;margin-bottom: 8px;}
.variantSinglePriceTxt{font-size: 0.8em;}
.variantSingleActions{width: $full-width;float: left;margin-top: 8px;}

.createOrderVaraintSelection{width: $full-width;float: left;}
.createOrderVaraintSelectionSingle{width: $full-width;float: left;}

.orderSummaryPricing{width: 100%;float: left;background: #ecf0fb;margin-top: 25px;border-radius: 20px;overflow: hidden;padding: 20px 25px;}
.orderSummaryPricingInner{width: 100%;float: left;}
.orderSummaryStepPricing{width: 100%;float: left;margin-bottom: 15px;border-bottom: 1px solid #dadada;padding-bottom: 15px;}
.orderSummaryStepPricingLable{width: 230px;float: left;}
.orderSummaryStepPricingLableTxt{float: left;text-transform: capitalize;font-size: 0.9em;}
.orderSummaryStepPricingValue{float: left;}
.orderSummaryStepPricingValueTxt{float: left;text-transform: capitalize;font-size: 0.9em;}
.orderSummaryStepPricingHighlight{width: 100%;float: left;}
.orderSummaryStepPricingHighlightLable{width: 230px;float: left;}
.orderSummaryStepPricingHighlightLableTxt{float: left;text-transform: capitalize;font-size: 1em;font-family: map-get($font, 'bold');}
.orderSummaryStepPricingHighlightValue{float: left;}
.orderSummaryStepPricingHighlightValueTxt{float: left;text-transform: capitalize;font-size: 1em;font-family: map-get($font, 'bold');}

.orderSummaryType{width: 50%;float: left;}

.orderSummaryActions{width: 50%;float: left;}
.orderSummaryActionsInner{float: right;}
.orderSummaryActionSingle{float: left;margin-right: 20px;}
.orderSummaryActionSingle:last-child{margin: 0px;}

.choosePackageSizeRadio{float: left;width: 100%;}
.choosePackageSizeDistance{width: 100%;float: left;margin-top: 5px;display: flex;justify-content: center;}
.choosePackageSizeDistanceTxt{float: left;border-bottom: 2px solid $blue-lighter;text-transform: uppercase;color: $blue-primary;}

.productQuantityPopUp{width: $full-width;float: left;display: flex;justify-content: space-between;}
.productQuantityPopUpTexts{float: left;display: flex;flex-direction: column;}
.productQuantityPopUpName{float: left;}
.productQuantityPopUpNameTxt{float: left;font-size: 1.3em;font-family: map-get($font, 'bold');color: $blue-primary;text-transform: capitalize;}
.productQuantityPopUpPrice{float: left;margin-top: 5px;}
.productQuantityPopUpPriceTxt{float: left;color: #000;font-size: 0.85em;text-transform: capitalize;}

.productQuantitySelector{float: left;}
.productQuantitySelectorAdd{width: 40px;height: 40px;background: $blue-primary;float: left;display: flex;justify-content: center;align-items: center;border-radius: 0px 5px 5px 0px;cursor: pointer;}
.productQuantitySelectorAdd:hover{background-color: $blue-darker;}
.productQuantitySelectorAddIcon{color: $white;font-size: 1em;}
.productQuantitySelectorMinus{width: 40px;height: 40px;background: $blue-primary;float: left;display: flex;justify-content: center;align-items: center;border-radius: 5px 0px 0px 5px;cursor: pointer;}
.productQuantitySelectorMinus:hover{background-color: $blue-darker;}
.productQuantitySelectorMinusIcon{color: $white;font-size: 1em;}
.productQuantitySelectorCount{width: 40px;height: 40px;background: $grey;float: left;display: flex;justify-content: center;align-items: center;}
.productQuantitySelectorCountTxt{font-family: map-get($font, 'semibold');color: $blue-primary;font-size: 1em;}

.productQuantitySelectorRemove{width: 40px;height: 40px;float: left;margin-left: 15px;background: $red-primary;display: flex;justify-content: center;align-items: center;border-radius: 5px;}
.productQuantitySelectorRemove:hover{background: $red-dark;}
.productQuantitySelectorRemoveIcon{color: $white;font-size: 1em;}

.invoiceContainer {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
}
.invoice {
  width: 300px;
  float: left;
  padding: 30px 0px;
}
.invoiceInner {
  width: 100%;
  float: left;
}
.invoiceBrandDetails {
  width: 100%;
  float: left;
}
.invoiceBrandName {
  width: 100%;
  float: left;
}
.invoiceBrandNameTxt {
  width: 100%;
  float: left;
  text-align: center;
  font-family: "Montserrat Extra Bold";
  font-size: 1.6em;
  text-transform: uppercase;
}
.invoiceBrandAddress {
  width: 100%;
  float: left;
  margin-top: 10px;
}
.invoiceBrandAddressTxt {
  width: 100%;
  text-align: center;
  font-size: 0.8em;
  line-height: 1.5em;
}
.invoiceBrandPhoneNumber {
  width: 100%;
  float: left;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.invoiceBrandGSTNumber {
  width: 100%;
  float: left;
  margin-top: 5px;
  display: flex;
  justify-content: center;
}
.invoiceBrandPhoneNumberTxt {
  float: left;
  font-size: 0.85em;
  line-height: 1.5em;
  text-transform: capitalize;
  text-align: center;
}

.invoiceDateOrderId {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.invoiceOrderId {
  float: left;
}
.invoiceOrderIdTxt {
  float: left;
  font-size: 0.85em;
}

.invoiceDate {
  float: left;
}
.invoiceDateTxt {
  float: left;
  font-size: 0.85em;
}

.invoiceOrderDetails {
  width: 100%;
  float: left;
  margin-top: 20px;
}
.invoiceOrderDetailsFields {
  width: 100%;
  float: left;
  border-top: 2px dashed #1e1826;
  border-bottom: 2px dashed #1e1826;
  margin-bottom: 10px;
}
.invoiceOrderDetailsFieldStep {
  float: left;
  display: flex;
  justify-content: center;
  padding: 10px 0px;
}

.invoiceOrderDetailsFieldStep.item {
  width: 106px;
}
.invoiceOrderDetailsFieldStep.qty {
  width: 50px;
}
.invoiceOrderDetailsFieldStep.price {
  width: 50px;
}
.invoiceOrderDetailsFieldStep.total {
  width: 50px;
}

.invoiceOrderDetailsFieldStepTxt {
  float: left;
  font-size: 0.85em;
  font-family: "Montserrat Bold";
  text-transform: capitalize;
}

.invoiceOrderDetailsItems {
  float: left;
}
.invoiceOrderDetailsItemsList {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}
.invoiceOrderDetailsItemSingle {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.invoiceOrderDetailsItemSingle:last-child {
  margin-bottom: 0px;
}

.invoiceOrderDetailsItemStep {
  float: left;
  padding: 10px 0px;
}

.invoiceOrderDetailsItemStep.item {
  width: 106px;
}
.invoiceOrderDetailsItemStep.qty {
  width: 50px;
}
.invoiceOrderDetailsItemStep.price {
  width: 50px;
}
.invoiceOrderDetailsItemStep.total {
  width: 50px;
}

.invoiceOrderDetailsItemStepTxt {
  float: left;
  font-size: 0.85em;
  text-transform: capitalize;
}

.invoiceOrderDetailsItemDetails {
  width: 100%;
  float: left;
}
.invoiceOrderDetailsItemDetailsStep {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.invoiceOrderDetailsItemDetailsStep:last-child {
  margin-bottom: 0px;
}
.invoiceOrderDetailsItemDetailsStepLable {
  width: 35%;
  float: left;
}
.invoiceOrderDetailsItemDetailsStepLableTxt {
  float: left;
  font-size: 0.85em;
  font-family: "Montserrat Bold";
  text-transform: capitalize;
}
.invoiceOrderDetailsItemDetailsStepValue {
  width: 65%;
  float: left;
}
.invoiceOrderDetailsItemDetailsStepValueTxt {
  float: left;
  font-size: 0.85em;
  text-transform: capitalize;
}

.invoiceOrderDetailsPriceBreakups {
  width: 100%;
  float: left;
  margin-top: 10px;
}
.invoiceOrderDetailsPriceBreakupStep {
  width: 100%;
  float: left;
  display: flex;
  justify-content: right;
  margin-bottom: 10px;
}
.invoiceOrderDetailsPriceBreakupStep:last-child {
  margin: 0px;
}
.invoiceOrderDetailsPriceBreakupStepInner {
  width: 190px;
  float: left;
}
.invoiceOrderDetailsPriceBreakupStepLable {
  width: 145px;
  float: left;
  margin-right: 5px;
}
.invoiceOrderDetailsPriceBreakupStepLableTxt {
  float: left;
  font-size: 0.85em;
  text-transform: capitalize;
}
.invoiceOrderDetailsPriceBreakupStepValue {
  width: 40px;
  float: left;
  display: flex;
  justify-content: center;
}
.invoiceOrderDetailsPriceBreakupStepValueTxt {
  float: left;
  font-size: 0.85em;
  text-transform: capitalize;
}

.invoiceOrderDetailsPriceTotal {
  width: 100%;
  float: left;
  border-top: 2px dashed #1e1826;
  border-bottom: 2px dashed #1e1826;
  margin-top: 20px;
  padding: 10px 0px;
}
.invoiceOrderDetailsPriceTotalLable {
  width: 50%;
  float: left;
}
.invoiceOrderDetailsPriceTotalValue {
  width: 50%;
  float: left;
  display: flex;
  justify-content: right;
}
.invoiceOrderDetailsPriceTotalTxt {
  float: left;
  font-size: 0.85em;
  text-transform: uppercase;
  font-family: "Montserrat Bold";
}
.invoiceThanks {
  width: 100%;
  margin-top: 20px;
  float: left;
  display: flex;
  justify-content: center;
}
.invoiceThanksTxt {
  float: left;
  font-size: 0.85em;
  text-transform: capitalize;
}

.invoiceServiceType{width: $full-width;float: left;margin-top: 20px;padding: 10px 0px;border-top: 2px dashed #000;border-bottom: 2px dashed #000;display: flex;justify-content: center;}
.invoiceServiceTypeTxt{text-transform: uppercase;font-size: 1.3em;font-family: map-get($font, 'bold');}

.invoicePaymentStatus{width: $full-width;float: left;margin-top: 20px;display: flex;justify-content: center;}
.invoicePaymentStatusTxt{text-transform: uppercase;font-size: 1em;font-family: map-get($font, 'bold');}

.revenueStep{width: $full-width;float: left;margin-top: 25px;display: flex;flex-direction: row;gap: 25px;}
.revenueStepHalf{width: 50%;float: left;}

.dashboardContentMainHeadingAction {
  float: right;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dashboardContentMainHeadingActionInner {
  float: left;
}
.dashboardContentMainHeadingActionTxt {
  float: left;
  padding: 12px 30px;
  background: #0c2447;
  border-radius: 3px;
  color: #ffffff;
  font-size: 0.9em;
  cursor: pointer;
  text-transform: capitalize;
}
.dashboardContentMainHeadingActionTxt:hover {
  background: #000;
}

.dashboardContentMainHeadingActionSwitch{
  float: left;
  border-radius: 3px;
  overflow: hidden;
  margin-top: -4px;
  margin-right: 20px;
}

.dashboardContentMainHeadingActionSwitchSingle{float: left;padding: 12px 30px;background: #0c2447;color: #ffffff;font-size: 0.9em;cursor: pointer;text-transform: capitalize;}
.dashboardContentMainHeadingActionSwitchSingle:disabled{background: #7e679a;cursor: not-allowed;}

.tooltip {
  border-radius: 0.25rem;
  background: #0a1f3e;
  color: #fff;
  padding: 1rem;
  box-shadow: 15px 30px 40px 5px #0a1f3e6c;
  /* text-align: center; */
}
.tooltipLable {
  margin-bottom: 6px;
}

/* hiding first and last lines in cartesian grid */
.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}

.ck-editor__editable_inline {
  min-height: 300px;
}

.pizzaCrustPrice{width: 100%;float: left;display: flex;justify-content: space-between;}
.pizzaCrustPriceContent{float: left;display: flex;}
.pizzaCrustPriceContentSingle{float: left;margin-right: 15px;display: flex;align-items: center;}
.pizzaCrustPriceContentSingle:last-child{margin: 0px;}
.pizzaCrustPriceContentSingleIcon{float: left;}
.pizzaCrustPriceContentSingleIco{float: left;}
.pizzaCrustPriceContentSingleText{float: left;margin-left: 10px;}
.pizzaCrustPriceContentSingleTxt{float: left;}
.pizzaCrustPriceContentSingleTxt .tableContentTxt{float: left;}
.pizzaCrustPriceContentSingleTxt i{font-size: 0.8em;margin: 6px 0px 0px 5px;float: left;}

.pizzaCrustPriceAction{float: left;}
.pizzaCrustPriceActionButton{float: left;height: 30px;border-radius: 5px;cursor: pointer;background: $blue-primary;padding: 0px 12px;display: flex;align-items: center;}
.pizzaCrustPriceActionButtonIco{color: #fff;font-size: 0.8em;}

.makeDefaultSizeCrust{width: $full-width;float: left;border: 2px dashed $blue-primary;border-radius: 5px;padding: 12px 20px;display: flex;justify-content: center;cursor: pointer;}
.makeDefaultSizeCrust:hover{border: 2px solid $blue-primary;background-color: $blue-primary;}
.makeDefaultSizeCrust:hover .makeDefaultSizeCrustTxt{color: $white;}
.makeDefaultSizeCrustTxt{float: left;font-size: 0.9em;font-family: map-get($font, "semibold");text-transform: capitalize;color: $blue-primary;}

.makeDefaultSizeCrust.selected{border: 2px dashed $red-primary;cursor: context-menu;}
.makeDefaultSizeCrust.selected .makeDefaultSizeCrustTxt{color: $dark-grey;}
.makeDefaultSizeCrust.selected:hover{border: 2px dashed $red-primary;background: none;}
.makeDefaultSizeCrust.selected:hover .makeDefaultSizeCrustTxt{color: $dark-grey;}
