// colors
$blue-primary: #0a1f3e;
$blue-dark: #041124;
$blue-darker: #000;
$blue-lighter: #7186a3;
$red-primary: #ff3737;
$red-dark: #bd0a0a;
$red-darker: #660404;

$brand-primary: #120e17;
$brand-dark: #050209;
$brand-light: #271143;

$text-dark: #3d3d3d;

$white: #fff;
$grey: #dedede;
$dark-grey: #515151;

// fonts
$font: (
    "thin": "Montserrat Thin",
    "light": "Montserrat Light",
    "extralight": "Montserrat Extra Light",
    "medium": "Montserrat Medium",
    "regular": "Montserrat Regular",
    "semibold": "Montserrat Semi Bold",
    "bold": "Montserrat Bold",
    "extrabold": "Montserrat Extra Bold",
    "black": "Montserrat Black",
);

// widths
$mobile: 650px;
$tab: 1200px;
$small: 1400px;
$medium: 1750px;
$large: 2000px;

$fixed-width: 1100px;
$full-width: 100%;

// paddings & margins
$padding: 25px;